import { Carrier, CarrierDTO } from "./CarrierDTO";
import { Constraint, ConstraintDTO } from "./ConstraintDTO";
import { ContactPcrClient, ContactPcrClientDTO } from "./ContactPcrClientDTO";
import { DocumentDTO, GetToPostDocument, PostDocumentDTO } from "./DocumentDTO";
import { EquipmentDTO } from "./EquipmentDTO";
import { Modality, ModalityDTO } from "./ModalityDTO";
import { Request, RequestDTO } from "./RequestDTO";
import { StakeholderDTO } from "./StakeholderDTO";

export interface GetLoanRequestDTO extends RequestDTO {
  intervenants: StakeholderDTO[],
  dateDeRetrait: string,
  equipements: EquipmentDTO[],
  client: string,
  adresseDeLivraison: {
    rue: string,
    codePostal: string,
    ville: string,
  },
  contrainteDeLivraison: ConstraintDTO |null,
  contact: {
    nom: string,
    service: string,
    fonction: string,
    telephone: string,
  },
  transporteur: CarrierDTO,
  prestation: {
    dateDeDebut: string,
    dateDeFin: string,
  } | null,
  dateDeLivraison: string,
  commentaire: string,
  modalite: ModalityDTO,
  contactPcrClient : ContactPcrClientDTO,
}

export interface PostLoanRequestDTO {
  numero: string,
  commentaire: string,
  modaliteId: number,
  adresseDeLivraison: {
    rue: string,
    codePostal: string,
    ville: string,
  },
  contrainteDeLivraisonId: number|null,
  dateDeLivraison: string,
  client: string,
  contact: {
    nom: string,
    service: string,
    fonction: string,
    telephone: string,
  },
  transporteurId: number,
  prestation: {
    dateDeDebut: string,
    dateDeFin: string,
  } | null,
  intervenantIds: number[],
  dateDeRetrait: string,
  equipementIds: number[],
  documents: PostDocumentDTO[],
  contactPcrClient : ContactPcrClientDTO,
}

export function GetToPostLoan(data: GetLoanRequestDTO): PostLoanRequestDTO {
  
  const format: PostLoanRequestDTO = {
    numero: data.numero,
    commentaire: data.commentaire,
    modaliteId: data.modalite.id,
    adresseDeLivraison: {
      rue: data.adresseDeLivraison.rue,
      codePostal: data.adresseDeLivraison.codePostal,
      ville: data.adresseDeLivraison.ville
    },
    contrainteDeLivraisonId: data.contrainteDeLivraison?.id || null,
    dateDeLivraison: data.dateDeLivraison,
    client: data.client,
    contact: {
      nom: data.contact.nom,
      service: data.contact.service,
      fonction: data.contact.fonction,
      telephone: data.contact.telephone
    },
    transporteurId: data.transporteur.id,
    prestation:  data.prestation ? {
      dateDeDebut: data.prestation.dateDeDebut,
      dateDeFin: data.prestation.dateDeFin
    } : null,
    intervenantIds: data.intervenants?data.intervenants.map((item:any)=>item.id):[],
    dateDeRetrait: data.dateDeRetrait,
    equipementIds: data.equipements.map((item:any)=>item.id),
    documents: data.demandeDocuments.map((item:DocumentDTO)=>GetToPostDocument(item)),
    contactPcrClient : data.contactPcrClient,
  }
  return format;
}

export default class LoanRequest extends Request {
  dateDeRetrait: string = new Date().toDateString()
  equipements = []
  modalite = new Modality()

  adresseDeLivraison: { rue: string; codePostal: string; ville: string } = {
    rue: "",
    codePostal: "",
    ville: ""
  }
  contrainteDeLivraison= new Constraint()
  dateDeLivraison: string = new Date().toDateString()
  client: string = ""
  contact: { nom: string; service: string; fonction: string; telephone: string } = {
    nom: "",
    service: "",
    fonction: "",
    telephone: ""
  }
  transporteur = new Carrier()
  prestation: { dateDeDebut: string; dateDeFin: string } = {
    dateDeDebut: new Date().toDateString(),
    dateDeFin: new Date().toDateString()
  }
  intervenants = []
  demandeDocuments = []
	finalite = 1 
  contactPcrClient = new ContactPcrClient()
  
  constructor() {
    super();
    this.type = 1
  }
}