import { Modality } from "./ModalityDTO";
import { PostResourceDTO, ResourceDTO } from "./ResourceDTO";

export interface EquipmentDTO extends ResourceDTO {
  numeroDeSerie: string,
  numeroDeCommande: string,
  dateDeSortieUsine: string | null,
  actif: boolean,
  finalite: number,
  typeDeRayonnement: ERayonnementType,
}

export interface PostEquipmentDTO extends PostResourceDTO {
  numeroDeSerie: string,
  numeroDeCommande: string,
  dateDeSortieUsine: string | null,
  actif: boolean,
  finalite: number,
  typeDeRayonnement: ERayonnementType,
}

export function GetToPostEquipment(data: EquipmentDTO): PostEquipmentDTO {
  const format: PostEquipmentDTO = {
    numeroDeSerie: data.numeroDeSerie,
    numeroDeCommande: data.numeroDeCommande,
    dateDeSortieUsine: data.dateDeSortieUsine,
    actif: data.actif,
    finalite: data.finalite,
    typeDeRayonnement: data.typeDeRayonnement,
    nom: data.nom,
    modaliteId: data.modalite.id
  }
  return format
}

export interface IEquipment extends EquipmentDTO {
}

export class Equipment implements IEquipment {
  numeroDeSerie: string = "";
  numeroDeCommande = "";
  dateDeSortieUsine = "";
  actif: boolean = true;
  finalite: number = 0;
  typeDeRayonnement: ERayonnementType = ERayonnementType.NonIonisant;
  nom: string = "";
  modalite = new Modality();
  id: number = 0;

  constructor(
    id: number = 0,
    modalite: Modality = new Modality(),
    nom: string = "",
    finalite: number = 0,
    typeDeRayonnement: ERayonnementType = ERayonnementType.NonIonisant,
    numeroDeSerie: string = "",
    numeroDeCommande: string = "",
    dateDeSortieUsine: string = "",
    actif: boolean = true,
  ) {
    this.actif = actif;
    this.finalite = finalite;
    this.typeDeRayonnement = typeDeRayonnement;
    this.numeroDeSerie = numeroDeSerie;
    this.numeroDeCommande = numeroDeCommande;
    this.dateDeSortieUsine = dateDeSortieUsine;
    this.nom = nom;
    this.modalite = modalite;
    this.id = id;
  };
  getResourceScope(dateDebut: Date | null = null, dateFin: Date | null = null): string {
    switch (this.finalite) {
      case 0:
        return "Demo";
      case 1:
        return "Pret";
      default:
        return "";
    }
  }
  getResourcePurpose() {
    return this.finalite
  }

}

export enum ERayonnementType{
  NonIonisant,
  Ionisant
}

export class EquipmentFieldFormat {
  srNum: string = "";
  cmdNum: string = "";
  factoryDate: string | null = null;
  active: boolean = true;
  purpose: number = 0;
  radiationType: ERayonnementType = ERayonnementType.NonIonisant;
  name: string = "";
  modality: Modality | null = null;
}