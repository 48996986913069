export interface ContactPcrClientDTO {
    nom: string,
    email: string,
}

export interface IContactPcrClient extends ContactPcrClientDTO {
}

export class ContactPcrClient implements IContactPcrClient {
    nom: string = "";
    email: string = "";
}