import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { DemonstrationRequestService } from "services/DemonstrationRequestService";
import { Form, Field, FormElement, FormRenderProps, } from "@progress/kendo-react-form";
import { FieldEquipment } from "components/CustomFormFields/FieldListBox";

import moment from "moment";

import DemoRequest, { GetDemoRequestDTO, GetToPostDemo } from 'model/DemoRequestDTO';
import { emailExternalValidator, equipmentsValidator, phoneValidator, postalCodeValidator, requiredValidator, stakeholdersValidator } from 'utils/FormValidators';
import { Button } from '@progress/kendo-react-buttons';
import { ModalityService } from 'services/ModalityService';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import FieldDateTimePicker from "components/CustomFormFields/FieldDateTimePicker";
import FieldInput from "components/CustomFormFields/FieldInput";
import FieldTextArea from "components/CustomFormFields/FieldTextArea";
import { modalitiesByType } from "utils/RequestHelpers";
import { CarrierService } from "services/CarrierService";
import { Spinner } from "components/Spinner/Spinner";
import { ConstraintService } from "services/ConstraintService";
import { ConstraintDTO } from "model/ConstraintDTO";
import FieldComboBox from "components/CustomFormFields/FieldComboBox";
import { FieldStakeholder } from "components/CustomFormFields/FieldListBox";
import { connect } from "react-redux";
import { RootState } from "store";
import { Modality, ModalityDTO } from "model/ModalityDTO";
import { ListView } from "@progress/kendo-react-listview";
import { FileItem } from "components/CustomFormFields/FileItem";
import { Dates } from "model/Dates";
import { useNotification } from "components/Notifications/NotificationProvider";
import { DocumentDTO } from "model/DocumentDTO";
import ResourceErrorPopup from "components/CustomPopups/ResourceErrorPopup";
import { ERayonnementType } from "model/EquipmentDTO";

const DemoRequestFormView = (props: any) => {

    const requestFieldFormat = {
        postalCode: "",
        street: "",
        city: "",
        client: "",
        comment: "",
        function: "",
        name: "",
        service: "",
        phone: "",
        constraint: null,
        shipping: new Date(),
        withdrawal: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        carrier: 0,
        equipements: [],
        stakeholders: [],
        finalite: 0,
        nameRso: "",
        mailRso: "",
    }

    const stakeholders = modalitiesByType.demo.filter((item: any) => item.type === 1).map((item: any) => item.nom);
    const navigate = useNavigate();
    const notify = useNotification();
    let { id } = useParams();
    let { modality } = useParams();
    const [request, setRequest] = useState<GetDemoRequestDTO>(new DemoRequest());
    const [modalityName, setModality] = useState<ModalityDTO>(new Modality());
    const [init, setInit] = useState(requestFieldFormat);
    const [constraints, setConstraints] = useState<ConstraintDTO[]>([]);
    const user = request?.user;
    const [visibleRsoBox, setVisibleRsoBox] = useState(false)

    const today = new Date();
    const [dates, setDates] = useState<Dates>({
        startDate: today,
        endDate: today,
        shipping: today,
        withdraw: today
    });
    useEffect(() => {
        handleInit();
    }, [])

    const handleWithdrawalChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, withdraw: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateDeRetrait: moment(event.value).format('YYYY-MM-DDTHH:mm:ss') }));
    }
    const handleShippingChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, shipping: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateDeLivraison: moment(event.value).format('YYYY-MM-DDTHH:mm:ss') }));
    }
    const handleStartDateChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, startDate: event.value }));
        setRequest(({ ...request }) => (request.prestation ? { ...request, prestation: { ...request.prestation, dateDeDebut: moment(event.value).format('YYYY-MM-DDTHH:mm:ss') } } : { ...request }));
    }
    const handleEndDateChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, endDate: event.value }));
        setRequest(({ ...request }) => ((request.prestation ? { ...request, prestation: { ...request.prestation, dateDeFin: moment(event.value).format('YYYY-MM-DDTHH:mm:ss') } } : { ...request })));
    }
    const handleIonizingEquipment = (itemList: any)=> {
            
            let formContainIonizingEquipment = false;
    
            let equipmentList = itemList;
            for (const equipment of equipmentList) {
                if (equipment.typeDeRayonnement === ERayonnementType.Ionisant) {
                    formContainIonizingEquipment = true;
                    break;
                }
            }
            if (formContainIonizingEquipment) {
                setVisibleRsoBox(true);
            } else {
                setVisibleRsoBox(false);
            }
        }


    const handleSubmit = (dataItem: { [name: string]: any; }) => {

        let result = request;
        result.adresseDeLivraison.codePostal = dataItem.postalCode;
        result.adresseDeLivraison.rue = dataItem.street;
        result.adresseDeLivraison.ville = dataItem.city;
        result.client = dataItem.client;
        result.commentaire = dataItem.comment;
        result.contact.fonction = dataItem.function;
        result.contact.nom = dataItem.name;
        result.contact.service = dataItem.service;
        result.contact.telephone = dataItem.phone;
        result.dateDeLivraison = moment(dataItem.shipping).format('YYYY-MM-DDTHH:mm:ss');
        result.dateDeRetrait = moment(dataItem.withdrawal).format('YYYY-MM-DDTHH:mm:ss');
        result.equipements = dataItem.equipements;
        result.intervenants = dataItem.stakeholders;
        result.contrainteDeLivraison = dataItem.constraint;
        result.contactPcrClient.nom = dataItem.nameRso;
        result.contactPcrClient.email = dataItem.mailRso;

        result.modalite = modalityName;
        if ((modalityName.nom === "AT C-ARMS" || modalityName.nom === "XP") && result.prestation) {
            result.prestation.dateDeDebut = moment(dataItem.startDate).format('YYYY-MM-DDTHH:mm:ss');
            result.prestation.dateDeFin = moment(dataItem.endDate).format('YYYY-MM-DDTHH:mm:ss');
        } else {
            result.prestation = null;
        }
        result.transporteur = request.transporteur;

        const requestService = new DemonstrationRequestService();
        if (props.layout === "edit" && id) {
            if (request.statut === 2 && props.user.role === "GL") {
                requestService.accept(+id, GetToPostDemo(result)).then(() => {
                    notify({ message: "Validation terminée", type: "success" })
                    navigate("/demandes/demo/")
                }
                ).catch((error) => {
                    if (error.request && error.request.status === 409) {
                        prepareBookedError(error, "validation")
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })
                });
            } else {
                requestService.update(+id, GetToPostDemo(result)).then(() => {
                    notify({ message: "Modification terminée", type: "success" })
                    navigate("/demandes/demo/")
                }
                ).catch((error) => {
                    if (error.request && error.request.status === 409) {
                        prepareBookedError(error, "modification")
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })
                })
            }
        } else {
            requestService.create(GetToPostDemo(result))
                .then((postRes: GetDemoRequestDTO) => {
                    requestService.publish(+postRes.id, GetToPostDemo(postRes)).then(() => {
                        notify({ message: "Ajout terminé", type: "success" })
                        navigate("/demandes/demo/")
                    }
                    ).catch((error) => {
                        notify({ message: error, type: "error" })
                    });
                }).catch((error) => {
                    if (error.request && error.request.status === 409) {
                        prepareBookedError(error, "ajout")
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })
                });
        }
    };

    function handleInit(): any {

        async function fetchModalityData(modalityId: number) {
            const modalityService = new ModalityService();
            const modalityResult = await modalityService.get(+modalityId)
                .catch((error) => notify({ message: error, type: "error" }))

            if (modalityResult && modalitiesByType.demo.find((item: any) => item.nom.toUpperCase() === modalityResult.nom.toUpperCase())) {
                setModality(modalityResult);
                setRequest(({ ...request }) => ({ ...request, modalite: modalityResult }))
            } else {
                navigate("/demandes/demo")
            }
        }
        async function fetchConstraintData() {
            const constraintService = new ConstraintService();
            const constraintResult = await constraintService.getAllConst()
                .catch((error) => notify({ message: error, type: "error" }))

            if (constraintResult && constraintResult.content.length) {
                setConstraints(constraintResult.content)
            }
        }
        new CarrierService().getAllTrans().then((data: any) => {
            setRequest(({ ...request }) => {
                let transporteur;
                if (data && data.length > 0) {
                    const transporteurs = data.filter((d: any) => d.defaultDemandeType === 0);
                    if (transporteurs.length > 0) {
                        transporteur = transporteurs[0];
                    } else {
                        transporteur = data[0];
                    }
                } else {
                    transporteur = null;
                }
                return {
                    ...request,
                    transporteur,
                };
            });
        })
            .catch((error) => notify({ message: error, type: "error" }))
        fetchConstraintData()

        if (props.layout === "edit" && id) {

            const requestService = new DemonstrationRequestService();
            requestService.get(+id).then((data: any) => {
                if (data) {
                    data.finalite = 0;
                    data.demandeId = id;
                    setRequest(data);
                    setDates({
                        startDate: data.prestation ? new Date(data.prestation.dateDeDebut) : null,
                        endDate: data.prestation ? new Date(data.prestation.dateDeFin) : null,
                        shipping: new Date(data.dateDeLivraison),
                        withdraw: new Date(data.dateDeRetrait),
                    })
                    const res = {
                        postalCode: data.adresseDeLivraison.codePostal,
                        street: data.adresseDeLivraison.rue,
                        city: data.adresseDeLivraison.ville,
                        client: data.client,
                        comment: data.commentaire,
                        function: data.contact.fonction,
                        name: data.contact.nom,
                        service: data.contact.service,
                        phone: data.contact.telephone,
                        constraint: data.contrainteDeLivraison,
                        shipping: data.dateDeLivraison,
                        withdrawal: data.dateDeRetrait,
                        startDate: data.prestation ? data.prestation.dateDeDebut : null,
                        endDate: data.prestation ? data.prestation.dateDeFin : null,
                        carrier: data.transporteur,
                        equipements: data.equipements.map((item: any) => item.id),
                        stakeholders: stakeholders.find((modality: string) => modalityName.nom === modality)
                            ? data.intervenants.map((item: any) => item.id)
                            : [],
                        finalite: 1,
                        demandeId: id,
                        nameRso: data.contactPcrClient.nom,
                        mailRso: data.contactPcrClient.email,
                    }
                    stakeholders.find((modality: string) => modalityName.nom === modality)
                        ? res.stakeholders = data.intervenants.map((item: any) => item.id)
                        :

                        setInit(res)


                    fetchModalityData(+data.modalite.id)
                    handleIonizingEquipment(data.equipements)
                }

            })
                .catch((error) => notify({ message: error, type: "error" }))

        } else if (props.layout === "create" && modality) {
            const modId = +modality;
            fetchModalityData(modId);

        }else if (props.layout === "duplicate" && id) {

            const requestService = new DemonstrationRequestService();
            requestService.get(+id).then((data: any) => {
                if (data) {
                    data.finalite = 0;
                    //data.demandeId = id;
                    //setRequest(data);
                    /*
                    setDates({
                        startDate: data.prestation ? new Date(data.prestation.dateDeDebut) : null,
                        endDate: data.prestation ? new Date(data.prestation.dateDeFin) : null,
                        shipping: new Date(data.dateDeLivraison),
                        withdraw: new Date(data.dateDeRetrait),
                    })*/
                    const res = {
                        postalCode: data.adresseDeLivraison.codePostal,
                        street: data.adresseDeLivraison.rue,
                        city: data.adresseDeLivraison.ville,
                        client: data.client,
                        comment: data.commentaire,
                        function: data.contact.fonction,
                        name: data.contact.nom,
                        service: data.contact.service,
                        phone: data.contact.telephone,
                        constraint: data.contrainteDeLivraison,
                        shipping: today,
                        withdrawal: today,
                        startDate: today,
                        endDate: today,
                        carrier: data.transporteur,
                        equipements: data.equipements.map((item: any) => item.id),
                        stakeholders: stakeholders.find((modality: string) => modalityName.nom === modality)
                            ? data.intervenants.map((item: any) => item.id)
                            : [],
                        finalite: 1,
                        //demandeId: id,
                        nameRso: data.contactPcrClient.nom,
                        mailRso: data.contactPcrClient.email,
                    }
                    stakeholders.find((modality: string) => modalityName.nom === modality)
                        ? res.stakeholders = data.intervenants.map((item: any) => item.id)
                        :

                        setInit(res)


                    fetchModalityData(+data.modalite.id)
                    handleIonizingEquipment(data.equipements)
                }

            })
                .catch((error) => notify({ message: error, type: "error" }))

        }
    }

    /*const generalUrl = "/demandes/demo"

    useEffect(() => {
        if (props.layout === "edit") {
            switch (props.user.role) {
                case "IC":
                    if (!request.user || request.user !== props.user)
                        navigate(generalUrl)
                    break;
                case "GL":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else if (props.layout === "create") {
            switch (props.user.role) {
                case "IC":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else {
            navigate(generalUrl)
        }
    }, [props.user, request])*/
    const withdrawValidator = (value: any) =>
        !value || !dates.withdraw || !dates.shipping
            ? "Précisez une date de retrait"
            : dates.withdraw < today
                ? "La date ne doit pas être dans le passé"
                : dates.withdraw < dates.shipping
                    ? "le retrait ne peut pas être avant la livraison"
                    : modalityName.nom === "AT C-ARMS" && dates.endDate && dates.withdraw < dates.endDate
                        ? "le retrait ne peut pas être avant la date de fin"
                        : ""

    const shippingValidator = () =>
        !dates.shipping
            ? "Précisez une date de livraison"
            : dates.shipping < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : ""

    const startDateValidator = (value: any) =>
        !value || !dates.shipping || !dates.startDate
            ? "Précisez une date de début"
            : dates.startDate < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : dates.startDate < dates.shipping
                    ? "la date de début ne peut pas être avant la livraison"
                    : ""

    const endDateValidator = (value: any) =>
        !value || !dates.startDate || !dates.endDate
            ? "Précisez une date de fin"
                : dates.endDate < dates.startDate
                    ? "la date de fin ne peut pas être avant la date de début"
                    : ""

    function handleDeny() {
        const requestService = new DemonstrationRequestService();
        requestService.deny(+id!, GetToPostDemo(request))
            .then(() => navigate("/demandes/demo")).catch((error) => {
                notify({ message: error, type: "error" })
            })
            .catch((error) => notify({ message: error, type: "error" }))

    }

    const buttonGroup = (formRenderProps: any) => {
        return (
            <>
                {(request.statut === 3 || request.statut === 4 || request.statut === 6) ?
                    <Button
                        name="back"
                        type="button"
                        fillMode="outline"
                        onClick={() => { navigate("/demandes/demo") }}
                    >
                        Retour
                    </Button>
                    :
                    <>
                        <Button
                            name="cancel"
                            type="button"
                            fillMode="outline"
                            onClick={() => { navigate("/demandes/demo") }}
                        >
                            Retour
                        </Button>
                        {(request.statut === 2 && props.user.role === "GL" && props.layout === "edit")
                            ? <>
                                <Button
                                    type={"button"}
                                    themeColor="error"
                                    onClick={handleDeny}
                                >
                                    Refuser
                                </Button>
                                <Button
                                    name="submit"
                                    type={"submit"}
                                    disabled={!formRenderProps.allowSubmit}
                                    themeColor="success"
                                >
                                    Valider
                                </Button>
                            </>
                            :
                            <>
                                <Button
                                    name="submit"
                                    type={"submit"}
                                    disabled={!formRenderProps.allowSubmit}
                                    themeColor={"primary"}
                                >
                                    Soumettre pour validation
                                </Button>
                            </>
                        }
                    </>
                }
            </>

        )
    }

    const CustomFileItem = (props: any) => (
        <FileItem
            {...props}
            deleteFile={deleteFile}
        />
    );
    function deleteFile(file: DocumentDTO) {
        const result = request.demandeDocuments.filter((item: DocumentDTO) => item !== file);
        setRequest(({ ...request }) => ({ ...request, demandeDocuments: result }));
    }

    const [visible, setVisible] = useState(false)
    const [errorType, setErrorType] = useState("")
    const [bookedError, setBookedError] = useState<any[]>([])

    function prepareBookedError(error: any, type: string) {
        let result: any[] = []
        if (error.request && error.request.response) {
            try {
                const resultJSON = JSON.parse(error.request.response)
                for (const error of resultJSON.errors) {
                    result.push(error)
                }
                setBookedError(result)
                if (type === "modification")
                    setErrorType("de modifier")
                else if (type === "ajout")
                    setErrorType("d'ajouter")
                else if (type === "validation")
                    setErrorType("de valider")

            } catch (error) {
                notify({ message: error, type: "error" })
            }
        }
    }

    function togglePopupError() {
        setVisible(!visible)
    }


    const anchor = useRef<HTMLButtonElement | null>(null);


    return (
        <>
            <ResourceErrorPopup anchor={anchor.current} togglePopupError={togglePopupError} type={errorType} bookedError={bookedError} visible={visible} />
            <Form
                initialValues={init}
                key={JSON.stringify(init)}
                onSubmit={handleSubmit}
                render={(formRenderProps: FormRenderProps) => (
                    <div className='wrapper'>
                        <FormElement>
                            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <span className="section-title">{props.layout === "edit" ? "Demande de Démonstration n°" + request.numero : "Nouvelle demande de Démonstration"}</span>
                                <div className="k-form-buttons"
                                    style={{ marginTop: "0" }}
                                >
                                    {buttonGroup(formRenderProps)}
                                </div>
                            </div>
                            <Card>
                                <div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Modalité</legend>
                                        <span className="modality">{modalityName.nom}</span>
                                    </div>
                                    {user? <div className='form-section'>
                                        <legend className={"k-form-legend"}>Demande faite par</legend>
                                        <span className="modality">{user?.firstName} {user?.lastName} </span>
                                    </div> : null }
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Livraison</legend>
                                        <StackLayout orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"shipping"}
                                                id={"shipping"}
                                                label={"Date et Heure de Livraison"}
                                                required={true}
                                                min={today}
                                                date={dates.shipping}
                                                component={FieldDateTimePicker}
                                                onChange={handleShippingChange}
                                                validator={shippingValidator}
                                            />
                                            <Field
                                                name={"withdrawal"}
                                                id={"withdrawal"}
                                                label={"Date et Heure de Retrait"}
                                                required={true}
                                                min={dates.endDate}
                                                date={dates.withdraw}
                                                component={FieldDateTimePicker}
                                                onChange={handleWithdrawalChange}
                                                validator={withdrawValidator}
                                            />
                                        </StackLayout>
                                        <div>
                                            <Field
                                                name={"equipements"}
                                                request={request}
                                                label={"Equipements"}
                                                required={true}
                                                layout={props.layout}
                                                finalite={0}
                                                component={FieldEquipment}
                                                validator={equipmentsValidator}
                                                onToolbarClick={handleIonizingEquipment}
                                            />
                                        </div>
                                    </div>
                                    {stakeholders.find((modality: string) => modalityName.nom === modality) ?
                                        <div className='form-section'>

                                            <div>
                                                <legend className={"k-form-legend"}>Démonstration</legend>

                                                <StackLayout
                                                    orientation="horizontal"
                                                    gap={20}
                                                >
                                                    <Field
                                                        name={"startDate"}
                                                        label={"Date et Heure de Début"}
                                                        required={true}
                                                        min={dates.shipping}
                                                        date={dates.startDate}
                                                        component={FieldDateTimePicker}
                                                        onChange={handleStartDateChange}
                                                        validator={startDateValidator}

                                                    />
                                                    <Field
                                                        name={"endDate"}
                                                        label={"Date et Heure de Fin"}
                                                        required={true}
                                                        min={dates.startDate}
                                                        date={dates.endDate}
                                                        component={FieldDateTimePicker}
                                                        onChange={handleEndDateChange}
                                                        validator={endDateValidator}
                                                    />
                                                </StackLayout>
                                                <Field
                                                    name={"stakeholders"}
                                                    label={"Intervenants"}
                                                    required={true}
                                                    request={request}
                                                    layout={props.layout}
                                                    purpose={0}
                                                    component={FieldStakeholder}
                                                    validator={stakeholdersValidator}
                                                />
                                            </div>
                                        </div> : null}
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Client</legend>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"client"}
                                                name={"client"}
                                                label={"Raison Sociale"}
                                                required={true}
                                                value={request.client}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <div></div>
                                        </StackLayout>
                                        <span className="k-form-separator"></span>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"street"}
                                                name={"street"}
                                                label={"Rue"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            {window.innerWidth > 900
                                                ? <div></div>
                                                : null
                                            }

                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"postalCode"}
                                                name={"postalCode"}
                                                label={"Code Postal"}
                                                required={true}
                                                component={FieldInput}
                                                validator={postalCodeValidator}
                                            />
                                            <Field
                                                id={"city"}
                                                name={"city"}
                                                label={"Ville"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                        <span className="k-form-separator"></span>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"name"}
                                                name={"name"}
                                                label={"Nom du contact"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                id={"service"}
                                                name={"service"}
                                                label={"Service"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"function"}
                                                name={"function"}
                                                label={"Fonction"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                id={"phone"}
                                                name={"phone"}
                                                label={"N° de Téléphone"}
                                                type={"tel"}
                                                required={true}
                                                component={FieldInput}
                                                validator={phoneValidator}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"constraint"}
                                                name={"constraint"}
                                                label={"Contrainte de Livraison"}
                                                component={FieldComboBox}
                                                data={constraints}
                                                textField={"nom"}
                                                dataItemKey={"id"}
                                            />
                                            <div></div>
                                        </StackLayout>
                                    </div>
                                    
                                    <div className='form-section'  style={{ display: (visibleRsoBox ? 'block' : 'none') }} >
                                        <legend className={"k-form-legend"}>Contact PCR Client</legend>
                                        <span className="rsoMeaning">Personne Compétente en Radioprotection</span>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"nameRso"}
                                                name={"nameRso"}
                                                label={"Nom du contact"}
                                                required={visibleRsoBox}
                                                disabled={!visibleRsoBox}
                                                component={FieldInput}
                                                validator={visibleRsoBox ? requiredValidator : undefined}
                                            />
                                            <Field
                                                id={"mailRso"}
                                                name={"mailRso"}
                                                label={"Email"}
                                                required={visibleRsoBox}
                                                disabled={!visibleRsoBox}
                                                component={FieldInput}
                                                validator={visibleRsoBox ? emailExternalValidator : undefined}
                                            />
                                        </StackLayout>
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Commentaire</legend>

                                        <Field
                                            name={"comment"}
                                            value={request.commentaire ? request.commentaire : ""}
                                            rows={4}
                                            component={FieldTextArea}
                                        />
                                    </div>
                                    {props.layout === "edit" ?
                                        <div className='form-section'>
                                            <legend className={"k-form-legend"}>Documents liés à la demande</legend>
                                            <ListView
                                                data={request.demandeDocuments}
                                                item={CustomFileItem}
                                            />
                                            {!request.demandeDocuments.length ?
                                                <span className="no-request-doc">Aucun document n'est lié à cette demande</span>
                                                : null}
                                        </div>
                                        : null}
                                    <div className="k-form-buttons">
                                        {buttonGroup(formRenderProps)}
                                    </div>
                                </div>
                            </Card>
                        </FormElement>
                    </div>


                )}

            />
            {props.loading
                ? <Spinner />
                : null
            }

        </>
    )
}

const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(DemoRequestFormView);