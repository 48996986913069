import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import { ListBox, ListBoxItemClickEvent, ListBoxToolbar, ListBoxToolbarClickEvent, processListBoxData } from "@progress/kendo-react-listbox";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { StakeholderDTO } from "../../model/StakeholderDTO";
import { StakeholderService } from "services/StakeholderService";
import { EquipmentService } from "services/EquipmentService";
import { setLoading } from "store/slices/globalSlice";
import { useAppDispatch } from "store";
import { ModalityDTO } from "../../model/ModalityDTO";
import { IFieldRenderPropsCustom } from "components/CustomFormFields/IFieldRenderPropsCustom";

export const FieldEquipment = (fieldRenderProps: IFieldRenderPropsCustom) => {

    const SELECTED_FIELD = "selected";
    const dispatch = useAppDispatch();

    const [available, setAvailable] = useState<{ id: number, name: string, mobility: number, typeDeRayonnement: number, selected: string }[]>([])
    const [requested, setRequested] = useState<{ id: number, name: string, mobility: number, typeDeRayonnement: number, selected: string }[]>([])

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        id,
        disabled,
        optional,
        label,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;


    useEffect(() => {

        const equipmentService = new EquipmentService();
        async function fetchEquipmentData() {
            //console.log(others);

            if (others.request.dateDeLivraison && others.request.dateDeRetrait && others.request.modalite.id) {
                //console.log(others.request)
                const startDate = moment(others.request.dateDeLivraison).format("YYYY-MM-D")
                let endDate = ""
                if (others.request.dateDeLivraison === others.request.dateDeRetrait) {
                    endDate = moment(others.request.dateDeRetrait).add(1, 'd').format("YYYY-MM-D")
                } else {
                    endDate = moment(others.request.dateDeRetrait).format("YYYY-MM-D")
                }
                const equipmentsResult: any[] = await equipmentService.getAvailables([others.request.modalite.id], others.request.finalite, startDate, endDate);

                if (equipmentsResult) {
                    return equipmentsResult
                }
            }
        }
        fetchEquipmentData().then((result: any) => { if (result) initialiseEdit(result); dispatch(setLoading(false)) });


    }, [others.request.dateDeLivraison, others.request.dateDeRetrait, others.request.modalite.id])

    function initialiseEdit(result: any) {
        let req: any[] = [];
        let ava: any[] = [];
        if (others.request.equipements) {
            req = others.request.equipements.map((item: any) => ({ "id": item.id, "name": item.name || item.nom, "typeDeRayonnement": item.typeDeRayonnement, "selected": false }))
        }
        ava = result.map((item: any) => ({ "id": item.id, "name": item.name || item.nom, "typeDeRayonnement": item.typeDeRayonnement, "selected": false }))

        setAvailable([...ava]);
        setRequested(req);
    }

    const handleItemAvailableClick = (event: ListBoxItemClickEvent) => {
        const newAvailable = available.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newRequested = requested.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleItemRequestedClick = (event: ListBoxItemClickEvent) => {

        const newRequested = requested.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newAvailable = available.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleToolBarClick = async (e: ListBoxToolbarClickEvent) => {
        let toolName: string = e.toolName || "";
        let result: any = processListBoxData(
            available,
            requested,
            toolName,
            SELECTED_FIELD
        );
        const newAvailable = result.listBoxOneData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id
            )) === index;
        });

        const newRequested = result.listBoxTwoData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id)) === index;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
        if(fieldRenderProps.onToolbarClick){
            fieldRenderProps.onToolbarClick(newRequested);
        }
    };

    const showValidationMessage: string | false | null =
        visited && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback(() => {
        onChange({ value: requested });
    }, [onChange, requested]);

    useEffect(() => {
        callback();

    }, [callback, requested])


    return (
        <FieldWrapper className={"col" + (others.required ? " required" : "")} >
            <Label className="listbox-field-title"
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}>
                {label}
            </Label>
            <div className={"listbox-field-layout" + (showValidationMessage ? " listbox-error" : "")} onFocus={onFocus} onBlur={onBlur}>
                <div className="listbox-item listbox-one" onFocus={onFocus} onBlur={onBlur}>
                    <Label className="listbox-title">Disponibles</Label>
                    <ListBox
                        data={available}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemAvailableClick(e)
                        }
                        toolbar={() => {
                            return (
                                <ListBoxToolbar
                                    tools={[
                                        "moveUp",
                                        "moveDown",
                                        "transferTo",
                                        "transferFrom",
                                        "transferAllTo",
                                        "transferAllFrom",
                                    ]}
                                    data={available}
                                    dataConnected={requested}
                                    onToolClick={handleToolBarClick}
                                />
                            );
                        }}
                    />
                </div>
                <div className="listbox-item k-pl-0">
                    <Label className="listbox-title">Sélectionnés</Label>
                    <ListBox
                        data={requested}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemRequestedClick(e)
                        }
                    />
                    {showValidationMessage && (
                        <Error id={errorId}>{validationMessage}</Error>
                    )}
                </div>
            </div>
        </FieldWrapper>
    )

}
export const FieldEquipmentReview = (fieldRenderProps: FieldRenderProps) => {

    const SELECTED_FIELD = "selected";
    const dispatch = useAppDispatch();

    const [available, setAvailable] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])
    const [requested, setRequested] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        id,
        disabled,
        optional,
        label,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;


    useEffect(() => {

        const equipmentService = new EquipmentService();
        async function fetchEquipmentData() {
            const startDate = moment(others.request.dateDebut).format("YYYY-MM-D")
            let endDate = ""
            if (others.request.dateDebut === others.request.dateFin) {
                endDate = moment(others.request.dateFin).add(1, 'd').format("YYYY-MM-D")
            } else {
                endDate = moment(others.request.dateFin).format("YYYY-MM-D")
            }
            const modaliteIds = others.modalites.map((m: ModalityDTO) => m.id)
            return await equipmentService.getAvailables(modaliteIds, others.request.finalite, startDate, endDate);
        }
        fetchEquipmentData().then((result: any) => { if (result) initialiseEdit(result); dispatch(setLoading(false)) });

    }, [others.request.dateDebut, others.request.dateFin])

    function initialiseEdit(result: any) {
        let req: any[] = others.request.equipements || [];
        let ava: any[] = result || [];

        req = others.request.equipements.map((item: any) => ({ "id": item.id, "name": item.name || item.nom, "selected": false }))

        if (result) {
            if (others.request.equipements) {
                ava = ava.filter((item: any) => !others.request.equipements.find((item2: any) => item2.id === item.id))
            }
            ava = ava.map((item: any) => ({ "id": item.id, "name": item.nom, "selected": false }))
        }
        setAvailable([...ava]);
        setRequested(req);
    }

    const handleItemAvailableClick = (event: ListBoxItemClickEvent) => {

        const newAvailable = available.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newRequested = requested.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleItemRequestedClick = (event: ListBoxItemClickEvent) => {

        const newRequested = requested.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newAvailable = available.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleToolBarClick = async (e: ListBoxToolbarClickEvent) => {
        let toolName: string = e.toolName || "";
        let result: any = processListBoxData(
            available,
            requested,
            toolName,
            SELECTED_FIELD
        );
        const newAvailable = result.listBoxOneData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id
            )) === index;
        });

        const newRequested = result.listBoxTwoData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id)) === index;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);

    };

    const showValidationMessage: string | false | null =
        visited && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback(() => {
        onChange({ value: requested });
    }, [onChange, requested]);

    useEffect(() => {
        callback();

    }, [callback, requested])

    return (
        <FieldWrapper className={"col" + (others.required ? " required" : "")} >
            <Label className="listbox-field-title"
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}>
                {label}
            </Label>
            <div className={"listbox-field-layout" + (showValidationMessage ? " listbox-error" : "")} onFocus={onFocus} onBlur={onBlur}>
                <div className="listbox-item listbox-one" onFocus={onFocus} onBlur={onBlur}>
                    <Label className="listbox-title">Disponibles</Label>
                    <ListBox
                        data={available}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemAvailableClick(e)
                        }
                        toolbar={() => {
                            return (
                                <div onFocus={onFocus} onBlur={onBlur}>
                                    <ListBoxToolbar
                                        tools={[
                                            "moveUp",
                                            "moveDown",
                                            "transferTo",
                                            "transferFrom",
                                            "transferAllTo",
                                            "transferAllFrom",
                                        ]}
                                        data={available}
                                        dataConnected={requested}
                                        onToolClick={handleToolBarClick}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="listbox-item k-pl-0">
                    <Label className="listbox-title">Sélectionnés</Label>
                    <ListBox
                        data={requested}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemRequestedClick(e)
                        }
                    />
                    {showValidationMessage && (
                        <Error id={errorId}>{validationMessage}</Error>
                    )}
                </div>
            </div>
        </FieldWrapper>
    )

}

export const FieldStakeholder = (fieldRenderProps: FieldRenderProps) => {

    const SELECTED_FIELD = "selected";
    const dispatch = useAppDispatch();

    const [available, setAvailable] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])
    const [requested, setRequested] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        id,
        disabled,
        optional,
        label,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;


    useEffect(() => {

        const stakeholderService = new StakeholderService();
        async function fetchStakeholderData() {
            if (others.request.prestation.dateDeDebut && others.request.prestation.dateDeFin && others.request.modalite.id && (others.purpose === 0 || others.purpose === 1)) {
                const startDate = moment(new Date(others.request.prestation.dateDeDebut)).format("YYYY-MM-D")
                let endDate = ""
                if (others.request.prestation.dateDeDebut === others.request.prestation.dateDeFin) {
                    endDate = moment(others.request.prestation.dateDeFin).add(1, 'd').format("YYYY-MM-D")
                } else {
                    endDate = moment(others.request.prestation.dateDeFin).format("YYYY-MM-D")
                }
                const stakeholderResult = await stakeholderService.getAvailables([others.request.modalite.id], others.purpose, startDate, endDate);
                //console.log(stakeholderResult);

                if (stakeholderResult) {
                    return stakeholderResult
                }
            }
        }
        fetchStakeholderData().then((result: any) => { if (result) initialiseEdit(result); dispatch(setLoading(false)) });

    }, [others.request.prestation?.dateDeFin, others.request.prestation?.dateDeDebut, others.request.modalite.id])

    function initialiseEdit(result: any) {
        let req: any[] = others.request.intervenants || [];
        let ava: any[] = result || [];

        req = others.request.intervenants.map((item: any) => ({ "id": item.id, "name": item.name || item.nom, "selected": false }))

        if (result) {
            if (others.request.intervenants) {
                ava = ava.filter((item: any) => !others.request.intervenants.find((item2: any) => item2.id === item.id))
            }
            ava = ava.map((item: any) => ({ "id": item.id, "name": item.nom, "selected": false }))
        }
        setAvailable(ava);
        setRequested(req);

    }

    const handleItemAvailableClick = (event: ListBoxItemClickEvent) => {

        const newAvailable = available.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newRequested = requested.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleItemRequestedClick = (event: ListBoxItemClickEvent) => {

        const newRequested = requested.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newAvailable = available.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleToolBarClick = async (e: ListBoxToolbarClickEvent) => {
        let toolName: string = e.toolName || "";
        let result: any = processListBoxData(
            available,
            requested,
            toolName,
            SELECTED_FIELD
        );
        const newAvailable = result.listBoxOneData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id
            )) === index;
        });

        const newRequested = result.listBoxTwoData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id)) === index;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);

    };

    const showValidationMessage: string | false | null =
        visited && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback(() => {
        onChange({ value: requested });
    }, [onChange, requested]);

    useEffect(() => {
        callback();

    }, [callback, requested])

    return (
        <FieldWrapper className={"col" + (others.required ? " required" : "")} >
            <Label className="listbox-field-title"
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}>
                {label}
            </Label>
            <div className={"listbox-field-layout" + (showValidationMessage ? " listbox-error" : "")} onFocus={onFocus} onBlur={onBlur}>
                <div className="listbox-item listbox-one" onFocus={onFocus} onBlur={onBlur}>
                    <Label className="listbox-title">Disponibles</Label>
                    <ListBox
                        data={available}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemAvailableClick(e)
                        }
                        toolbar={() => {
                            return (
                                <div onFocus={onFocus} onBlur={onBlur}>
                                    <ListBoxToolbar
                                        tools={[
                                            "moveUp",
                                            "moveDown",
                                            "transferTo",
                                            "transferFrom",
                                            "transferAllTo",
                                            "transferAllFrom",
                                        ]}
                                        data={available}
                                        dataConnected={requested}
                                        onToolClick={handleToolBarClick}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="listbox-item k-pl-0">
                    <Label className="listbox-title">Sélectionnés</Label>
                    <ListBox
                        data={requested}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemRequestedClick(e)
                        }
                    />
                    {showValidationMessage && (
                        <Error id={errorId}>{validationMessage}</Error>
                    )}
                </div>
            </div>
        </FieldWrapper>
    )

}
export const FieldStakeholderVacation = (fieldRenderProps: FieldRenderProps) => {

    const SELECTED_FIELD = "selected";
    const dispatch = useAppDispatch();

    const [available, setAvailable] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])
    const [requested, setRequested] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        id,
        disabled,
        optional,
        label,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;


    useEffect(() => {

        const stakeholderService = new StakeholderService();
        async function fetchStakeholderData() {
            if (others.request.dateDebut && others.request.dateFin) {

                const stakeholderResult = await stakeholderService.getAllResources();

                if (stakeholderResult) {
                    return stakeholderResult
                }
            }
        }
        fetchStakeholderData().then((result: any) => { if (result) initialiseEdit(result); dispatch(setLoading(false)) });

    }, [])

    function initialiseEdit(result: any) {

        let req: any[] = [];
        let ava: any[] = [];

        const selected = result.filter((stakeholder: StakeholderDTO) => { return others.request.intervenants.find((item: any) => item.id === stakeholder.id) })
        const notSelected = result.filter((stakeholder: StakeholderDTO) => { return !others.request.intervenants.find((item: any) => item.id === stakeholder.id) })

        req = selected.map((item: any) => ({ "id": item.id, "name": item.nom, "selected": false }))
        ava = notSelected.map((item: any) => ({ "id": item.id, "name": item.nom, "selected": false }))

        setAvailable([...ava]);
        setRequested(req);
    }

    const handleItemAvailableClick = (event: ListBoxItemClickEvent) => {

        const newAvailable = available.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newRequested = requested.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleItemRequestedClick = (event: ListBoxItemClickEvent) => {

        const newRequested = requested.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newAvailable = available.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleToolBarClick = async (e: ListBoxToolbarClickEvent) => {
        let toolName: string = e.toolName || "";
        let result: any = processListBoxData(
            available,
            requested,
            toolName,
            SELECTED_FIELD
        );
        const newAvailable = result.listBoxOneData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id
            )) === index;
        });

        const newRequested = result.listBoxTwoData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id)) === index;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);

    };

    const showValidationMessage: string | false | null =
        visited && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback(() => {
        onChange({ value: requested });
    }, [onChange, requested]);

    useEffect(() => {
        callback();

    }, [callback, requested])

    return (
        <FieldWrapper className={"col" + (others.required ? " required" : "")} >
            <Label className="listbox-field-title"
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}>
                {label}
            </Label>
            <div className={"listbox-field-layout" + (showValidationMessage ? " listbox-error" : "")} onFocus={onFocus} onBlur={onBlur}>
                <div className="listbox-item listbox-one" onFocus={onFocus} onBlur={onBlur}>
                    <Label className="listbox-title">Disponibles</Label>
                    <ListBox
                        data={available}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemAvailableClick(e)
                        }
                        toolbar={() => {
                            return (
                                <div onFocus={onFocus} onBlur={onBlur}>
                                    <ListBoxToolbar
                                        tools={[
                                            "moveUp",
                                            "moveDown",
                                            "transferTo",
                                            "transferFrom",
                                            "transferAllTo",
                                            "transferAllFrom",
                                        ]}
                                        data={available}
                                        dataConnected={requested}
                                        onToolClick={handleToolBarClick}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="listbox-item k-pl-0">
                    <Label className="listbox-title">Sélectionnés</Label>
                    <ListBox
                        data={requested}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemRequestedClick(e)
                        }
                    />
                    {showValidationMessage && (
                        <Error id={errorId}>{validationMessage}</Error>
                    )}
                </div>
            </div>
        </FieldWrapper>
    )

}

export const FieldStakeHolderInstall = (fieldRenderProps: FieldRenderProps) => {

    const SELECTED_FIELD = "selected";
    const dispatch = useAppDispatch();

    const [available, setAvailable] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])
    const [requested, setRequested] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        id,
        disabled,
        optional,
        label,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;

    useEffect(() => {

        const stakeholderService = new StakeholderService();
        async function fetchStakeholderData() {
            if (others.request.dateApplication1 && others.request.modalite.id && (others.purpose === 0 || others.purpose === 1)) {
                const appDate1 = moment(others.request.dateApplication1).format("YYYY-MM-D")
                const appDate1Fin = moment(others.request.dateApplication1).add(1, 'd').format("YYYY-MM-D")

                let stakeholderResult1: StakeholderDTO[] = await stakeholderService.getAvailables([others.request.modalite.id], others.purpose, appDate1, appDate1Fin);
                let stakeholderResult2: StakeholderDTO[] = [];
                let stakeholderResult3: StakeholderDTO[] = [];

                if (others.request.dateApplication2) {
                    const appDate2 = moment(others.request.dateApplication2).format("YYYY-MM-D")
                    const appDate2Fin = moment(others.request.dateApplication2).add(1, 'd').format("YYYY-MM-D")
                    stakeholderResult2 = await stakeholderService.getAvailables([others.request.modalite.id], others.purpose, appDate2, appDate2Fin);
                }

                if (others.request.dateApplication3) {
                    const appDate3 = moment(others.request.dateApplication3).format("YYYY-MM-D")
                    const appDate3Fin = moment(others.request.dateApplication3).add(1, 'd').format("YYYY-MM-D")
                    stakeholderResult3 = await stakeholderService.getAvailables([others.request.modalite.id], others.purpose, appDate3, appDate3Fin);
                }

                if (stakeholderResult2.length) {
                    stakeholderResult1 = stakeholderResult1.filter((value: StakeholderDTO) => stakeholderResult2.find((item: StakeholderDTO) => item.id === value.id))

                }
                if (stakeholderResult3.length) {
                    stakeholderResult1 = stakeholderResult1.filter((value: StakeholderDTO) => stakeholderResult3.find((item: StakeholderDTO) => item.id === value.id))
                }

                if (stakeholderResult1.length) {
                    return stakeholderResult1
                }

            }
        }
        fetchStakeholderData().then((result: any) => { initialiseEdit(result); });

    }, [others.request.dateApplication1, others.request.dateApplication2, others.request.dateApplication3, others.request.modalite.id])

    function initialiseEdit(result: any) {
        let req: any[] = others.request.intervenants || [];
        let ava: any[] = result || [];

        req = others.request.intervenants.map((item: any) => { return ({ "id": item.id, "name": item.nom || item.name, "selected": false }) })

        if (result) {
            if (others.request.intervenants) {
                ava = ava.filter((item: any) => !others.request.intervenants.find((item2: any) => item2.id === item.id))
            }
            ava = ava.map((item: any) => ({ "id": item.id, "name": item.nom, "selected": false }))
        }
        setAvailable(ava);
        setRequested(req);
    }

    const handleItemAvailableClick = (event: ListBoxItemClickEvent) => {

        const newAvailable = available.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newRequested = requested.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleItemRequestedClick = (event: ListBoxItemClickEvent) => {

        const newRequested = requested.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newAvailable = available.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleToolBarClick = async (e: ListBoxToolbarClickEvent) => {
        let toolName: string = e.toolName || "";
        let result: any = processListBoxData(
            available,
            requested,
            toolName,
            SELECTED_FIELD
        );
        const newAvailable = result.listBoxOneData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id
            )) === index;
        });

        const newRequested = result.listBoxTwoData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id)) === index;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);

    };

    const showValidationMessage: string | false | null =
        visited && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback(() => {
        onChange({ value: requested });
    }, [onChange, requested]);

    useEffect(() => {
        callback();

    }, [callback, requested])

    return (
        <FieldWrapper className={"col" + (others.required ? " required" : "")}>
            <Label className="listbox-field-title"
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}>
                {label}
            </Label>
            <div className={"listbox-field-layout" + (showValidationMessage ? " listbox-error" : "")} onFocus={onFocus} onBlur={onBlur}>
                <div className="listbox-item listbox-one" onFocus={onFocus} onBlur={onBlur}>
                    <Label className="listbox-title">Disponibles</Label>
                    <ListBox
                        data={available}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemAvailableClick(e)
                        }
                        toolbar={() => {
                            return (
                                <div onFocus={onFocus} onBlur={onBlur}>
                                    <ListBoxToolbar
                                        tools={[
                                            "moveUp",
                                            "moveDown",
                                            "transferTo",
                                            "transferFrom",
                                            "transferAllTo",
                                            "transferAllFrom",
                                        ]}
                                        data={available}
                                        dataConnected={requested}
                                        onToolClick={handleToolBarClick}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="listbox-item k-pl-0">
                    <Label className="listbox-title">Sélectionnés</Label>
                    <ListBox
                        data={requested}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemRequestedClick(e)
                        }
                    />
                    {showValidationMessage && (
                        <Error id={errorId}>{validationMessage}</Error>
                    )}
                </div>
            </div>
        </FieldWrapper>
    )

}